<!-- @format -->
<script lang="ts">
  import type { StoresKey } from "~/stores";
  import { getStoresContext } from "~/stores";
  import PinkWave from "../common/PinkWave.svelte";
  import OutlineTitle from "../common/OutlineTitle.svelte";
  import HighlightPillButton from "../HighlightPillButton.svelte";
  import BodyContent, { NONE } from "../BodyContent.svelte";
  import Carousel from "./Carousel.svelte";
  import OfferCard from "./OfferCard.svelte";
  import type { ManageAPI } from "~/manage-api/types";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;

  let pinkWavePosition:any = '';

  const stores_context = getStoresContext(request_store_key);
  $: offerStreamID = getFragmentConfig("offerStreamID", fragment); // 0: All, 1: Basic, 2: What's new, 3: Services, 4: Responsibility. 
  $: showTitle = getFragmentConfig("showTitle", fragment);
  $: showMoreLink = getFragmentConfig("showMoreLink", fragment);
  $: filters_disabled = getFragmentConfig("filtersDisabled", fragment);
  $: pinkWavePosition = getFragmentConfig("pinkWavePosition", fragment);
  $: border = getFragmentConfig("border", fragment);
  const { offers, T, reverse_router, store, site, lang } = stores_context;
  const { getFragmentConfig } = stores_context.fieldUtils();

  $: t = $T;
  
  const BASIC = 1,
    WHATS_NEW = 2;

  const filterBy = (stream_id: number, include_orphans: boolean) => () => {
    return getOffersByStream(stream_id, include_orphans);
  };

  const filter_btns = [
    {
      id: BASIC,
      label: 'offers-stream-basic',
      filter: filterBy(BASIC, true),
      className: 'offer-lift-switch-button-left'
    },
    {
      id: WHATS_NEW,
      label: 'offers-stream-whats-new',
      filter: filterBy(WHATS_NEW, false),
      className: 'offer-lift-switch-button-right'
    }
  ];

  let show_filters = false;
  let filter_active = BASIC;
  let offers_filtered: ManageAPI.Offer[] = [];
  $: offersLanguage = $site.offersLanguage;
  $: offersDisplay = offersLanguage?.includes($lang)
  $: if ($offers) {
    const has_whats_new_offers = !!(filter_btns[1].filter() || []).length;
    show_filters = !filters_disabled && has_whats_new_offers && offerStreamID == "0";
  }

  $: {
    if (offerStreamID && offerStreamID != "0") {
      offers_filtered = getOffersByStream(Number(offerStreamID), false);
    } else if (filter_active) {
      if ($store) {
        offers_filtered = $offers.filter(
          (offer) => offer?.store?.id == $store.id,
        );
      } else {
        offers_filtered =
          filter_btns.find((btn) => btn.id == filter_active)?.filter() ||
          [];
      }
    }
    offers_filtered = offers_filtered.sort(() => Math.random() - 0.5);
  }

  function toggle(btn: any) {
    filter_active = btn.id;
  }                                                                                                                                                                                                                                                                                                                                                                                                                             

  function getOffersByStream(stream_id: number, include_orphans: boolean) {
    return $offers.filter((offer) => {
      const streams = offer.publicationStreams || [];
      const is_in_stream = streams.some(({ id }) => id == stream_id);
      const no_streams = !streams.length;
      return include_orphans ? is_in_stream || no_streams : is_in_stream;
    });
  }
  $: more_offers_href =  $reverse_router && showMoreLink
    ? $reverse_router.findPurposeRoute("offers collection")
    : "";

  let id = 0;
  function focusOffer(event: CustomEvent) {
    id = event.detail;
  }
</script>

{#if offers_filtered && offers_filtered.length && offersDisplay}
  <PinkWave will_be={pinkWavePosition}>
    <BodyContent padding={NONE}>
      <div class="offer-lift-wrapper" data-nosnippet>
        <!-- Offer title -->
        {#if showTitle}
          <OutlineTitle>
            <div>{t`offers`}</div>
            <br />
          </OutlineTitle>
        {/if}

        <!-- Filter component -->
        {#if show_filters}
          <div class="offer-lift-switch-button">
            {#each filter_btns as btn}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                class:active={btn.id == filter_active}
                class={btn.className}
                on:click={() => toggle(btn)}
              >
                {t(btn.label)}
              </div>
            {/each}
          </div>
        {/if}

        <!-- Offer carousel -->
        <Carousel>
          <svelte:fragment slot="swiper">
            {#each offers_filtered as offer}
              <swiper-slide>
                <OfferCard
                  {offer}
                  bordered={border}
                  on:focusOffer={focusOffer}
                  {request_store_key}></OfferCard>
              </swiper-slide>
            {/each}
          </svelte:fragment>
        </Carousel>

        <!-- Show more offer link -->
        {#if showMoreLink}
          <HighlightPillButton href={more_offers_href} class="section-button">
            {t`offers-show-more`}
          </HighlightPillButton>
        {/if}
      </div>
    </BodyContent>
  </PinkWave>
{/if}

<style lang="scss">
  .offer-lift-wrapper {
    width: 100%;
    position: relative;
    :global(.section-button) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
    :global(.swiper-button-prev),
    :global(.swiper-button-next) {
      top: unset;
      bottom: 16px;
      @media screen and (min-width: 576px) {
        top: 50%;
        bottom: unset;
      }
    }
  }

  .offer-lift-switch-button {
    text-align: center;
    width: 100%;
  }

  .offer-lift-switch-button-left,
  .offer-lift-switch-button-right {
    width: 45%;
    height: 43px;
    cursor: pointer;
    display: inline-block;
    border: 1.5px solid #fa4616;
    position: relative;
    line-height: 40px;
    background: #fff;
    color: #fa4616;
  }

  .offer-lift-switch-button-left.active,
  .offer-lift-switch-button-right.active {
    background: #fa4616;
    color: #fff;
  }

  .offer-lift-switch-button-left {
    border-radius: 23px 0 0 23px;
    right: -2.5px;
  }

  .offer-lift-switch-button-right {
    border-radius: 0 23px 23px 0;
    left: -2.5px;
  }

  @media screen and (min-width: 576px) {
    .offer-lift-switch-button-left,
    .offer-lift-switch-button-right {
      width: 210px;
    }
  }

  .section-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 45px;
    margin-top: 200px;
    border-radius: 23px;
    text-align: center;
    font-size: 20px;
    line-height: 45px;
    color: #fff;
    background: #fa4616;

    @media screen and (min-width: 576px) {
      margin-top: 270px;
    }
  }
</style>
