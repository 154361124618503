<script lang="ts">
  import PillButton from './PillButton.svelte';

  export let href = '';
  let className = '';
  export { className as class };

  const styles = { width: '300px' };
</script>

<PillButton class={className} on:click {href} {styles} hover={true}>
  <slot>See more</slot>
</PillButton>
